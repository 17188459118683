import React from 'react';
import { Theme } from '../../theme/Theme';
import StyledIcon from './Icon.style';

const Icon = ({
  d = null,
  size = null,
  viewBox = null,
  width = 20,
  height = 20,
  fill = null,
  title = null,
  children,
  ...p
}) => {
  if (size) {
    width = size;
    height = size;
  }
  if (!viewBox) viewBox = `0 0 ${width} ${height}`;

  return (
    <StyledIcon
      width={width}
      height={height}
      viewBox={viewBox}
      fill={fill ? Theme.colors[fill] : 'currentColor'}
      xmlns="http://www.w3.org/2000/svg"
      {...p}
    >
      {!!title && <title>{title}</title>}
      {!!d && <path d={d} />}
      {children}
    </StyledIcon>
  );
};

export default Icon;
